import React from "react";
import { Link } from "gatsby";
import { CompasBreakLine, Button } from "utils";
import PartnerIcon from "./PartnerIcon";
import PartnerContact from "./PartnerContact";
import { constContent, searchingPartner } from "staticContent/more/partners";

const { icons, pageTitle } = constContent;
const { contactMethods, text, title, btn } = searchingPartner;

const partnersIcons = icons.map((icon, index) => (
  <PartnerIcon key={index} {...icon} />
));

const partnersContacts = contactMethods.map((method, index) => (
  <PartnerContact key={index} {...method} />
));

const advertText = text.map((paragraph, index) => (
  <p key={index} className="partners__text">
    {paragraph}
  </p>
));

const Partners = () => (
  <>
    <section className="section hero partners">
      <h1 className="hero__banner-text">{pageTitle}</h1>
    </section>
    <section className="section">
      <div className="partners__icons-wrapper">{partnersIcons}</div>
      <CompasBreakLine />
      <h2 className="partners__subtitle">{title}</h2>
      <div className="partners__contact-box">
        {partnersContacts}
        <Link to="/contact">
          <Button className="contact-box__btn" children={btn} />
        </Link>
      </div>
      {advertText}
    </section>
  </>
);

export default Partners;
