import React from "react";

const PartnerContact = ({ info, icon: { src, alt } }) => (
  <div className="contact-box__block">
    <img
      className={`contact-box__block--icon ${
        info.includes("@") ? "envelope" : ""
      }`}
      loading="lazy"
      src={src}
      alt={alt}
    />
    <p className="contact-box__block--info">
      {info.includes("@") ? (
        <a href={`mailto:${info}`} target="_top">
          {info}
        </a>
      ) : (
        info
      )}
    </p>
  </div>
);

export default PartnerContact;
