import { icons, partnersLogo } from "routes/picPath";
import { partners } from "routes/externalPaths";

const constContent = {
  pageTitle: "Partnerzy",
  icons: [
    {
      name: "tensoft",
      pic: partnersLogo.tensoft,
      path: partners.tensoft,
    },
    {
      name: "st",
      pic: partnersLogo.st,
      path: partners.systherm,
    },
    {
      name: "hsi",
      pic: partnersLogo.hsi,
      path: partners.hsi,
    },
    {
      name: "esri",
      pic: partnersLogo.esri,
      path: partners.esri,
    },
    {
      name: "shh",
      pic: partnersLogo.shh,
      path: partners.shh,
    },
    {
      name: "oracle",
      pic: partnersLogo.oracle,
      path: partners.oracle,
    },
    {
      name: "smartFactor",
      pic: partnersLogo.smartFactor,
      path: partners.smartFactor,
    },
  ],
};

const searchingPartner = {
  title: "Poszukujemy partnera technologicznego !",
  contactMethods: [
    {
      info: "+48 32 420 73 54",
      icon: {
        src: icons.phone,
        alt: "ikona telefonu",
      },
    },
    {
      info: "sekretariat@hyperview.pl",
      icon: {
        src: icons.envelope,
        alt: "ikona koperty",
      },
    },
  ],
  btn: "zapraszamy\ndo kontaktu",
  text: [
    "W związku z rozwojem działalności oraz otwarciem na nowe wyzwania poszukujemy partnera technologicznego. Zapraszamy do współpracy firmy posiadające doświadczenie w matematycznym modelowaniu hydraulicznym, a także diagnostyce sieci wodociągowej oraz kanalizacyjnej.",
    "Posiadamy wieloletnie doświadczenie we współpracy z jednostkami samorządu terytorialnego, a w szczególności w zakresie integracji z systemami do prowadzenia PZGiK, finansowo-księgowymi itp.",
  ],
};

export { constContent, searchingPartner };
