import React from "react";
import Layout from "utils/Layout";
import Partners from "components/Partners";
import "styles/components/partners.scss";
import { constContent } from "staticContent/more/partners";

const IndexPage = () => (
  <Layout subTitle={constContent.pageTitle}>
    <Partners />
  </Layout>
);

export default IndexPage;
