import React from "react";

const PartnerIcon = ({ path, pic, name }) => (
  <a
    href={path}
    target="_blank"
    rel="noreferrer noopener"
    className="partners__partner-icon-block"
  >
    <img
      loading="lazy"
      src={pic}
      alt={`logo ${name}`}
      className="partners__partner-icon"
    />
  </a>
);

export default PartnerIcon;
